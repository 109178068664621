<template>
    <div id="MemberSystemDonateIndex" class="border-system-style">
        <!-- <NavBar></NavBar> -->
        <div id="donate-process-wrap" class="serif">
            <img :src="require('@/assets/logo/vertical-logo.png')" />
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
export default {
    name: 'MemberSystemDonateIndex',
}
</script>
<script setup></script>
<style lang="scss" scoped>
#MemberSystemDonateIndex {
    border: none;
}
</style>
